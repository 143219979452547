<template>
  <div class="module_one_block">
    <div class="module_one_header_block">
      <div class="module_one_header_container">
        <div class="module_one_header_label">
          {{ course.title }}
        </div>
      </div>
    </div>
    <div class="module_one_main_block">
      <div class="module_one_main_container">
        <div class="module_one_content_container">
          <div class="module_one_plot m-0" v-if="!course">
            <el-skeleton :rows="6" />
          </div>
          <div class="module_one_plot m-0" v-else>
            <router-link
              :to="{ name: 'about-course', slug: course.slug }"
              class="module_one_plot_module not-decoration"
              @click.native="showReviewBlock = false"
            >
              {{ $t("About_course") }}
            </router-link>
            <router-link
              v-for="module in course.modules"
              :to="{
                name: 'course-module',
                params: { slug: course.slug, module: module.id },
              }"
              :class="{ active: $route.params.module === module.id }"
              class="module_one_plot_module not-decoration"
              @click.native="showReviewBlock = false"
            >
              {{ module.title }}
            </router-link>
            <router-link
              v-if="course.quiz"
              :to="{ name: 'final-testing', params: { slug: course.slug } }"
              class="module_one_plot_module"
            >
              {{ $t("Final_testing") }}
            </router-link>
            <div class="module_one_plot_module active">Отзыв по курсу</div>
          </div>
        </div>
        <div class="module_one_desc">
          <div class="review_module_desc" v-if="!course.reviews[0].is_answered">
            <div class="theme_one_desc_title">Отзыв</div>
            <div class="review_module_desc_text_container">
              <div class="theme_one_desc_text">
                {{
                  course.reviews.filter((item) => item.type === 1)[0].questions[
                    currentQuestion - 1
                  ].text
                }}
              </div>

              <div
                class="review_rating_block"
                v-if="
                  course.reviews.filter((item) => item.type === 1)[0].questions[
                    currentQuestion - 1
                  ].type === `Rating`
                "
              >
                <span
                  class="review_rating_item"
                  @click="rating = 1"
                  :class="{ active: rating === 1 }"
                  >1</span
                >
                <span
                  class="review_rating_item"
                  @click="rating = 2"
                  :class="{ active: rating === 2 }"
                  >2</span
                >
                <span
                  class="review_rating_item"
                  @click="rating = 3"
                  :class="{ active: rating === 3 }"
                  >3</span
                >
                <span
                  class="review_rating_item"
                  @click="rating = 4"
                  :class="{ active: rating === 4 }"
                  >4</span
                >
                <span
                  class="review_rating_item"
                  @click="rating = 5"
                  :class="{ active: rating === 5 }"
                  >5</span
                >
              </div>
              <div
                class="review_rating_block"
                style="flex-direction: column"
                v-if="
                  course.reviews.filter((item) => item.type === 1)[0].questions[
                    currentQuestion - 1
                  ].type === `Rating`
                "
              >
                <span class="theme_one_desc_text">Оставьте свой отзыв</span>
                <textarea
                  class="type_raw_answers_text"
                  rows="6"
                  v-model="ratingText"
                >
                </textarea>
              </div>
              <div
                class="review_question_options_block"
                v-if="
                  course.reviews.filter((item) => item.type === 1)[0].questions[
                    currentQuestion - 1
                  ].type === `TYPE_SINGLE`
                "
              >
                <el-radio
                  v-for="answer in course.reviews.filter(
                    (item) => item.type === 1,
                  )[0].questions[currentQuestion - 1].answers"
                  v-model="checkList"
                  :label="answer.id"
                  >{{ answer.text_cleared }}</el-radio
                >
              </div>
              <div
                class="review_question_options_block"
                v-if="
                  course.reviews.filter((item) => item.type === 1)[0].questions[
                    currentQuestion - 1
                  ].type === `TYPE_MULTIPLE`
                "
              >
                <el-checkbox-group
                  v-model="checkList"
                  style="display: flex; flex-direction: column"
                >
                  <el-checkbox
                    v-for="answer in course.reviews.filter(
                      (item) => item.type === 1,
                    )[0].questions[currentQuestion - 1].answers"
                    :label="answer.id"
                  >
                    {{ answer.text_cleared }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div
                class="review_question_options_block"
                v-if="
                  course.reviews.filter((item) => item.type === 1)[0].questions[
                    currentQuestion - 1
                  ].type === `TYPE_RAW_ANSWERS`
                "
              >
                <textarea
                  class="type_raw_answers_text"
                  rows="6"
                  v-model="checkList"
                >
                </textarea>
              </div>
            </div>
            <div class="theme_one_desc_btn">
              <a
                href="#"
                class="theme_one_desc_btn_next"
                v-if="
                  course.reviews.filter((item) => item.type === 1)[0].questions
                    .length !== currentQuestion
                "
                @click="nextPage"
                >Далее</a
              >
              <a
                @click="sendReview"
                class="review_post_btn"
                v-if="
                  course.reviews.filter((item) => item.type === 1)[0].questions
                    .length === currentQuestion
                "
                >Отправить отзыв</a
              >
            </div>
          </div>
          <div class="review_module_desc" v-else>
            <div class="theme_one_desc_title">Отзыв</div>
            <div class="review_module_desc_text_container">
              <div class="review_module_text_done">Отзыв получен. Спасибо!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "CourseModule",
  data() {
    return {
      course: false,
      showReview: false,
      showReviewBlock: false,
      currentQuestion: 1,
      answersArr: {},
      checkList: [],
      rating: "",
      ratingText: "",
    };
  },
  methods: {
    nextPage() {
      if (this.checkList.length === 0) {
        return Vue.toastr({
          message: "Error",
          description: "Выберите ответ",
          type: "error",
        });
      }

      let questionData;

      const question =
        this.course.reviews[0].questions[this.currentQuestion - 1];

      if (question.type === "TYPE_RAW_ANSWERS") {
        questionData = {
          review_id: this.course.reviews[0].id,
          review_question_id: question.id,
          answer: this.checkList,
          rating: 0,
        };
      } else {
        questionData = {
          review_id: this.course.reviews[0].id,
          review_question_id: question.id,
          review_question_answer_id: this.checkList,
          answer: this.checkList,
          rating: 0,
        };
      }

      let apiRoot = API_ROOT;
      this.$http
        .post(`${apiRoot}/api/review/make`, questionData)
        .then((res) => {
          console.log("res", res);
          this.checkList = [];
        })
        .catch((err) => {
          console.log("err", err);
        });

      this.currentQuestion++;
    },

    sendReview() {
      let sendData;
      const review_id = this.course.reviews[0].id;

      if (this.course.reviews[0].has_rating === 1) {
        if (this.rating.length === 0 || this.ratingText.length === 0) {
          return Vue.toastr({
            message: "Error",
            description: "Выберите ответ",
            type: "error",
          });
        }

        sendData = { review_id, rating: this.rating, answer: this.ratingText };
      } else {
        if (this.checkList.length === 0) {
          return Vue.toastr({
            message: "Error",
            description: "Выберите ответ",
            type: "error",
          });
        }

        const question =
          this.course.reviews[0].questions[this.currentQuestion - 1];

        if (question.type === "TYPE_RAW_ANSWERS") {
          sendData = {
            review_id: this.course.reviews[0].id,
            review_question_id: question.id,
            answer: this.checkList,
            rating: 0,
          };
        } else {
          sendData = {
            review_id: this.course.reviews[0].id,
            review_question_id: question.id,
            review_question_answer_id: this.checkList,
            answer: this.checkList,
            rating: 0,
          };
        }
      }

      let apiRoot = API_ROOT;
      this.$http
        .post(`${apiRoot}/api/review/make`, sendData)
        .then((res) => {
          console.log("res", res);
          this.getCourseDetails();
          this.checkList = [];
          this.currentQuestion = 1;
          this.showReviewBlock = false;
          this.rating = "";
          Vue.toastr({
            message: "Success",
            description: "Отзыв отправлен",
            type: "success",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    getCourseDetails() {
      let apiRoot = API_ROOT;
      let slug = this.$route.params.slug;
      this.$http
        .get(`${apiRoot}/api/course/${slug}`)
        .then((res) => {
          this.course = res.body;
          this.course.reviews = this.course.reviews.filter(
            (item) => item.type === 1,
          );

          if (this.course.reviews[0].has_rating === 1) {
            this.course.reviews[0].questions.push({
              type: "Rating",
              text: "Оцените данный модуль!",
              answers: [],
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },

    goToPrevModule() {
      let prevModule = this.getModule("prev");
      if (prevModule && prevModule.id) {
        this.$router.push({
          name: "course-module",
          params: {
            slug: this.course.slug,
            module: prevModule.id,
          },
        });
      } else {
        this.$router.push({
          name: "about-course",
          params: {
            slug: this.course.slug,
          },
        });
      }
    },
    goToNextModule() {
      let nextModule = this.getModule("next");
      if (nextModule && nextModule.id) {
        this.$router.push({
          name: "course-module",
          params: {
            slug: this.course.slug,
            module: nextModule.id,
          },
        });
      } else {
        if (this.course.quiz) {
          this.$router.push({
            name: "final-testing",
            params: { slug: this.course.slug },
          });
        }
      }
    },
    getModule: function (direction) {
      if (!this.course.modules) return false;
      let i = 0;
      let max = this.course.modules.length;
      for (i; i < max; i += 1) {
        if (this.course.modules[i].id === this.currentModule.id) {
          return direction === "next"
            ? this.course.modules[i + 1]
            : this.course.modules[i - 1];
        }
      }
    },
    getProgressWidth(progress) {
      if (progress > 0 && progress < 40) progress = 40;
      return progress + "%";
    },
    getDate(date) {
      if (!date) return;
      const formatter = new Intl.DateTimeFormat("ru-RU", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
      let d = new Date(date);
      return formatter.format(d);
    },
  },
  computed: {
    showNext() {
      let nextModule = this.getModule("next");
      if (nextModule && nextModule.id) {
        return true;
      } else {
        return false;
      }
    },
    currentModule: function () {
      if (!this.course.modules) return false;
      let currentModuleId = parseInt(this.$route.params.module);
      return this.course.modules.find(
        (module) => module.id === currentModuleId,
      );
    },
  },
  watch: {
    currentQuestion: function (newQuestion, oldQuestion) {
      console.log("newQuestion", newQuestion);
      console.log("oldQuestion", oldQuestion);
      console.log("radio", this.radio);
      console.log("checkList", this.checkList);
      const question = this.course.reviews.filter((item) => item.type === 2)[0]
        .questions[oldQuestion - 1];
      const questionData = {
        review_id: this.course.reviews.filter((item) => item.type === 2)[0].id,
        review_question_id: question.id,
        review_question_answer_id: this.checkList,
        answer: this.checkList,
        rating: 5,
      };
      this.answersArr = questionData;
      this.checkList = [];
      console.log("this.answersArr", this.answersArr);
    },
  },
  mounted() {
    this.getCourseDetails();
  },
};
</script>

<style scoped>
.review_module_text_done {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  font-weight: 500;
  color: #c460c0;
  margin-top: auto;
  margin-bottom: auto;
}

.not-decoration {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.m-right-1 {
  margin-right: 1%;
}

.m-right-2 {
  margin-right: 2%;
}

.m-right-3 {
  margin-right: 3%;
}

.m-right-4 {
  margin-right: 4%;
}

.m-right-5 {
  margin-right: 5%;
}

.m-right-6 {
  margin-right: 6%;
}

.m-right-7 {
  margin-right: 7%;
}

.m-right-8 {
  margin-right: 8%;
}

.m-right-9 {
  margin-right: 9%;
}

.m-right-10 {
  margin-right: 10%;
}

.m-right-11 {
  margin-right: 11%;
}

.m-right-12 {
  margin-right: 12%;
}

.m-right-13 {
  margin-right: 13%;
}

.m-right-14 {
  margin-right: 14%;
}

.m-right-15 {
  margin-right: 15%;
}
</style>
